import React from "react";
import { updateUrl, useUrl } from "./url"

const Link = (props) => {
  const url = useUrl()

  const handleClick = (e) => {
    e.preventDefault()
    updateUrl(props.href, url.search)
  }

  return (
    <a {...props} onClick={handleClick} />
  )
}

export default Link
