import * as firebase from "firebase";
import * as firebaseui from "firebaseui";
import { useState, useEffect } from "react";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAW_UJNNzP1uP94AcomjHSh5B0brEc8nh0",
  authDomain: "bridget-jones.firebaseapp.com",
  databaseURL: "https://bridget-jones.firebaseio.com",
  projectId: "bridget-jones",
  storageBucket: "bridget-jones.appspot.com",
  messagingSenderId: "171531213160"
});

var uiConfig = {
  signInSuccessUrl: "",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      defaultCountry: 'GB'
    }
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: "<your-tos-url>",
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    window.location.assign("<your-privacy-policy-url>");
  }
};

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());

const debugPhone = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("phone");
};

// The start method will wait until the DOM is loaded.
if (!debugPhone()) {
  console.log("Setup auth")
}

export default app;

export const useFirebaseAuth = () => {
  const debug = debugPhone()
  const initialUser = debug ? { phoneNumber: debug } : null
  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    if (initialUser) return;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        setUser(user);
      } else {
        ui.start("#firebaseui-auth-container", uiConfig);
        setUser(null);
      }
    });
  }, [])

  return [user];
};
