import React from "react"
import "./Admin.css"
import Link from "./Link"

import { adminResetGame, adminClearNames } from "./api"
import { BOARD_ROUTE } from "./routes";

const AdminAction = ({ action, text }) => {
  const withConfirm = (cb) => () => {
    // eslint-disable-next-line
    if (confirm(`Are you sure you want to ${text}`)) {
      action();
    }
  }

  return <button className="AdminAction" onClick={withConfirm(action)}>
    {text}
  </button>
}

const Admin = () => {
  return (
    <ul className="AdminList">
      <li>
        <AdminAction text="Reset Game" action={adminResetGame}></AdminAction>
      </li>
      <li>
        <AdminAction text="Clear Names" action={adminClearNames}></AdminAction>
      </li>
      <li>
        <Link href={BOARD_ROUTE}>Back to game</Link>
      </li>
    </ul>
  )
}

export default Admin
