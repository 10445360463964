import React from "react"
import "./Admin.css"
import Link from "./Link"

import { revealHand, writeDeal, setDummy, unsetDummy } from "./api"
import { updateUrl } from "./url"
import { BOARD_ROUTE, ADMIN_ROUTE } from "./routes"

const AdminAction = ({ action, text }) => {
  const withConfirm = (cb) => () => {
    // eslint-disable-next-line
    if (confirm(`Are you sure you want to ${text}`)) {
      action();
    }
  }

  return <button className="AdminAction" onClick={withConfirm(action)}>
    {text || "Text"}
  </button>
}

const values = o => Object.keys(o).map(k => o[k])

const GameOptions = ({players}) => {
  console.log("PLAYERS", players)
  return (
    <ul className="AdminList">
      <li>
        Pick Dummy
        <div className="AdminMultiButton">
          {values(players).map(player => (
            player.isDummy
             ? <AdminAction text={`✅ ${player.name}`} action={unsetDummy} />
             : <AdminAction text={player.name} action={() => setDummy(player.seat)} />
          ))}
        </div>
      </li>
      <li>
        <AdminAction text="Re-Deal" action={() => writeDeal("N")} />
      </li>
      <li>
        <Link href={ADMIN_ROUTE}>Admin</Link>
      </li>
      <li>
        <Link href={BOARD_ROUTE}>Back to game</Link>
      </li>
    </ul>
  )
}

export default GameOptions
