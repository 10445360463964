import firebase from "firebase";
import { HEART, DIAMOND, SPADE, CLUB, equalCard } from "./suits";
import {useState, useEffect} from "react"

export const shuffle = (arr) => [...arr].sort((a,b) => Math.random() > 0.5 ? 1 : -1)

const compass = ["N","E","S","W"]

export function getDeck() {
  const suits = [HEART, DIAMOND, SPADE, CLUB];
  const values = [2, 3, 4, 5, 6, 7, 8, 9, 10, "J", "Q", "K", "A"];
  const deck = []
  suits.forEach(suit => {
    values.forEach(value => {
      deck.push({ suit, value })
    })
  })

  return shuffle(shuffle(shuffle(deck)))
}

export function deal(dealer) {
  const game = {
    dealer: dealer,
    hands: {
      N: { cards: [] },
      E: { cards: [] },
      S: { cards: [] },
      W: { cards: [] },
    }
  }

  const cards = getDeck()
  const dealOrder = [1,2,3,4].map(n => compass[(compass.indexOf(dealer) + n) % 4])

  cards.forEach((card,i) => {
    game.hands[dealOrder[i % 4]].cards.push(card)
  })

  return game
}

export function adminResetGame() {
  firebase.database().ref(`game`).set({
    play: {
      hands: {},
      table: {}
    },
    seats: {}
  })
}

export function takeSeat(phoneNumber, seat) {
  firebase.database().ref(`game/seats/${seat}`).set(phoneNumber)
}

export function writeDeal(dealer) {
  if (!dealer) throw new Error("Dealer required")
  const data = deal(dealer)
  unsetDummy()
  firebase.database().ref("game/play").set(data)
}

export function playCard(position, card) {
  firebase.database().ref(`game/play/table/${position}`).set(card)
}

export const removeMatchingCards = (big, little) => {
  return [...big].filter(bigCard => {
    return !little.some(littleCard => equalCard(bigCard, littleCard))
  })
}

export function assignWinner(position, cards) {
  firebase.database().ref(`game/play/wins/${position}`).transaction(list => {
    const newList = [...(list || [])]
    newList.push(cards)
    return newList
  }).then(() => {
    firebase.database().ref(`game/play/table`).set({})
    firebase.database().ref(`game/play/hands`).transaction(hands => {
      return {
        N: {
          ...hands.N,
          cards: removeMatchingCards(hands.N.cards, cards),
        },
        E: {
          ...hands.E,
          cards: removeMatchingCards(hands.E.cards, cards),
        },
        S: {
          ...hands.S,
          cards: removeMatchingCards(hands.S.cards, cards),
        },
        W: {
          ...hands.W,
          cards: removeMatchingCards(hands.W.cards, cards),
        },
      }
    })
  })
}

export function useDbRef(ref, defaultVal) {
  const [state, setState] = useState(defaultVal)

  useEffect(() => {
    const gameRef = firebase.database().ref(ref)
    gameRef.on("value", (snapshot) => {
      const val = snapshot.val()
      if (val) {
        setState(snapshot.val())
      }
    })
  }, [])

  return [state]
}

export function loginUser(phoneNumber, name) {
  firebase.database().ref(`players/${phoneNumber}`).set({
    name
  })
}

export function adminClearNames() {
  firebase.database().ref(`players`).remove()
  firebase.database().ref(`game`).remove()
}

export function setDummy(seat) {
  firebase.database().ref(`game/play/dummy`).set(seat)
}

export function unsetDummy(seat) {
  firebase.database().ref(`game/play/dummy`).remove()
}
