import React, { useState } from "react";
import styled, {keyframes} from "styled-components";
import "./Hand.css";
import { HEART, DIAMOND, SPADE, CLUB, scoreCard, equalCard } from "./suits";
import Card from "./Card";

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const HandWrapper = styled.div`
  width: 100%;
`;

const Suit = styled.div`
  display: ${p => (p.inline ? "inline-block" : "block")};
`;

const offsetUp = "-120%";
const offsetDown = "120%";
const CardWrapper = styled.div`
  display: inline-block;
  transform: translateY(
    ${p => (p.selected ? (p.down ? offsetDown : offsetUp) : "0")}
  );
  opacity: 0;
  animation: ${appear} 0.1s linear forwards;
  animation-delay: ${p => p.index * 0.1}s;
`;

const Hand = ({ cards, vertical, onPlayed, reverse, isTopHand }) => {
  const sorted = [...cards].sort((a, b) => {
    return scoreCard(a) > scoreCard(b) ? 1 : -1;
  });

  const [selected, setSelected] = useState(null);
  const [played, setPlayed] = useState(null);

  const onSelect = card => {
    if (!onPlayed) return;
    if (equalCard(card, selected)) {
      onPlayed(card);
      setPlayed(card);
    } else {
      setSelected(card);
    }
  };

  let n = 0;

  const renderCards = suit => {
    let suitCards = sorted.filter(
      card => suit === card.suit && !equalCard(card, played)
    );

    if (reverse) {
      suitCards = [...suitCards].reverse();
    }

    return suitCards.map((card) => (
      <CardWrapper key={card.suit + card.value + 'wrapper'} index={n++} selected={equalCard(selected, card)} down={isTopHand}>
        <Card
          key={card.suit + card.value}
          card={card}
          selected={equalCard(selected, card)}
          onSelect={onSelect}
          narrow={vertical}
        />
      </CardWrapper>
    ));
  };

  return (
    <HandWrapper>
      {[SPADE, HEART, DIAMOND, CLUB].map(suit => (
        <Suit key={suit} inline={!vertical}>
          {renderCards(suit)}
        </Suit>
      ))}
    </HandWrapper>
  );
};

export default Hand;
