import React from "react";
import styled, { css } from "styled-components";
import Card from "./Card";
import { HEART } from "./suits";

const bearingTitles = {
  N: "North",
  E: "East",
  S: "South",
  W: "West"
};

const leftCss = css`
  left: 2.5vw;
  transform: translateY(-50%);
`;
const rightCss = css`
  right: 2.5vw;
  transform: translateY(-50%);
`;
const centerCss = css`
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TableWrapper = styled.div`
  width: 45vw;
  height: 45vw;
  position: absolute;
  top: 50%;
  border: 2px #333 solid;
  border-radius: 5px;
  ${p => p.left && leftCss}
  ${p => p.right && rightCss}
  ${p => p.center && centerCss}
`;

const PlayerName = styled.div`
  position: absolute;
  text-align: center;
  font-weight: bold;
  background: rgb(150, 220, 254);
  display: inline-block;
  padding: 2px;
  font-size: 80%;
  transform-origin: 50% 50%;
`;

const PlayerNameTop = styled(PlayerName)`
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PlayerNameBottom = styled(PlayerName)`
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const PlayerNameLeft = styled(PlayerName)`
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
`;

const PlayerNameRight = styled(PlayerName)`
  right: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(90deg);
`;

const CardWrapper = styled.div`
  position: absolute;
  transform-origin: 50% 50%;
`;

const CardWrapperLeft = styled(CardWrapper)`
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const CardWrapperTop = styled(CardWrapper)`
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
`;

const CardWrapperBottom = styled(CardWrapper)`
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
`;

const CardWrapperRight = styled(CardWrapper)`
  left: 75%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const getPlayerInfo = (game, players, bearing) => {
  const phoneNumber = game.seats[bearing];
  const player = phoneNumber && players[phoneNumber];
  const name = (player && player.name) || bearingTitles[bearing];
  const card = game.play.table && game.play.table[bearing];
  const score =
    game.play && game.play.wins && (game.play.wins[bearing] || []).length;
  const title = `${name}: ${score || 0}`;

  return { card, title };
};

const Table = ({ game, playerPosition, players, left, right, center }) => {
  const positions = ["N", "E", "S", "W"];
  const bearings = {
    Bottom: playerPosition,
    Left: positions[(positions.indexOf(playerPosition) + 1) % 4],
    Top: positions[(positions.indexOf(playerPosition) + 2) % 4],
    Right: positions[(positions.indexOf(playerPosition) + 3) % 4]
  };

  const playerTop = getPlayerInfo(game, players, bearings.Top);
  const playerBottom = getPlayerInfo(game, players, bearings.Bottom);
  const playerLeft = getPlayerInfo(game, players, bearings.Left);
  const playerRight = getPlayerInfo(game, players, bearings.Right);

  center = !right && !left

  return (
    <TableWrapper left={left} right={right} center={center}>
      <PlayerNameTop>{playerTop.title}</PlayerNameTop>
      <CardWrapperTop>
        <Card card={playerTop.card} />
      </CardWrapperTop>

      <PlayerNameBottom>{playerBottom.title}</PlayerNameBottom>
      <CardWrapperBottom>
        <Card card={playerBottom.card} />
      </CardWrapperBottom>

      <PlayerNameLeft>{playerLeft.title}</PlayerNameLeft>
      <CardWrapperLeft>
        <Card card={playerLeft.card} />
      </CardWrapperLeft>

      <PlayerNameRight>{playerRight.title}</PlayerNameRight>
      <CardWrapperRight>
        <Card card={playerRight.card} />
      </CardWrapperRight>
    </TableWrapper>
  );
};

export default Table;
