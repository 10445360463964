import React from "react";
import styled, { css } from "styled-components";
import { SPADE, HEART, DIAMOND, CLUB } from "./suits";
import "./Card.css";

const color = suit => {
  switch (suit) {
    case SPADE:
      return "rgb(5, 43, 167)";
    case HEART:
      return "rgb(199, 5, 5)";
    case DIAMOND:
      return "rgb(221, 112, 10)";
    case CLUB:
      return "rgb(3, 141, 22)";
    default:
      return "black";
  }
};

const Card = styled.div`
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  color: ${p => color(p.suit)};
  border-radius: 3px;
  background: white;

  font-weight: bold;
  line-height: 1;
  font-size: 20px;
  display: inline-block;
  overflow: hidden;
  text-align: left;

  ${p =>
    p.narrow &&
    css`
      margin-right: -${p.width * 0.35}px;
    `}
`;

const Value = styled.span`
  display: block;
`;

const Suit = styled.span`
  display: none;

  ${Card}:first-child & {
    display: block;
  }
`;

const PlayingCard = ({
  card,
  index = 0,
  selected,
  narrow,
  onSelect = () => {}
}) => {
  const width = Math.floor(document.documentElement.clientWidth / 13);
  const height = Math.floor(width * 1.5);

  if (!card) return null;

  return (
    <Card
      onClick={() => onSelect(card)}
      suit={card.suit}
      width={width}
      height={height}
      narrow={narrow}
    >
      <Value>{card.value === 10 ? "T" : card.value}</Value>
      <Suit>{card.suit}</Suit>
    </Card>
  );
};

export default PlayingCard;
