import React, { useState } from "react";
import "./App.css";
import { HEART, SPADE, DIAMOND, CLUB, equalCard } from "./suits";
import SideHand from "./SideHand";
import PlayableHand from "./PlayableHand";
import Table from "./Table";
import Login from "./Login";
import Admin from "./Admin";
import Link from "./Link";
import SeatPicker from "./SeatPicker";
import AssignWinner from "./AssignWinner";
import GameOptions from "./GameOptions";
import { useFirebaseAuth } from "./firebase";
import { useDbRef, writeDeal, playCard, takeSeat, loginUser } from "./api";
import { useUrl, updateUrl } from "./url";
import { matchRoute, ADMIN_ROUTE, OPTIONS_ROUTE } from "./routes";
import {
  playersBySeat,
  oppositeSeat,
  playerByPhone,
  nextSeat,
  prevSeat
} from "./gameData";

const findSeat = (seats, phoneNumber) => {
  if (!seats) return;

  return Object.keys(seats).find(position => seats[position] === phoneNumber);
};

export const isAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has("admin");
};

const startGame = () => {
  writeDeal("N");
};

const isPhil = user => {
  if (!user) throw new Error("no user");
  if (!user.phoneNumber) return null;
  return user.phoneNumber.match(/31628343674$/);
};

const App = () => {
  const [user] = useFirebaseAuth();
  const [game] = useDbRef("game", {});
  const [players] = useDbRef("players", {});
  const playerData = playersBySeat({ game, players });

  const url = useUrl();

  // Firebase will log us in
  if (!user || !user.phoneNumber) return null;

  if (!players[user.phoneNumber]) {
    return <Login onSubmit={name => loginUser(user.phoneNumber, name)} />;
  }

  if (matchRoute(url, ADMIN_ROUTE) && isPhil(user)) {
    return <Admin />;
  }

  if (matchRoute(url, OPTIONS_ROUTE) && isPhil(user)) {
    return <GameOptions players={playerData} />;
  }

  const seats = game.seats || {};
  const seat = findSeat(seats, user.phoneNumber);
  if (!seats.N || !seats.E || !seats.S || !seats.W) {
    return <SeatPicker game={game} players={players} user={user} />;
  }

  if (isPhil(user) && (!game || !game.play)) {
    return <button onClick={startGame}>Deal</button>;
  }

  const me = playerByPhone(playerData, user.phoneNumber);
  const toLeft = playerData[nextSeat(me.seat)];
  const partner = playerData[oppositeSeat(me.seat)];
  const toRight = playerData[prevSeat(me.seat)];

  const cards = game.play.hands[seat].cards;

  const onPlayed = played => {
    playCard(me.seat, played);
  };

  const onPlayedTop = played => {
    playCard(partner.seat, played);
  };

  return (
    <div>
      {toLeft && toLeft.isDummy && (
        <>
          <SideHand cards={toLeft.hand.cards} left />
          <Table game={game} playerPosition={seat} players={players} right />
        </>
      )}

      {toRight && toRight.isDummy && (
        <>
          <SideHand cards={toRight.hand.cards} right />
          <Table game={game} playerPosition={seat} players={players} left />
        </>
      )}

      {!((toLeft && toLeft.isDummy) || (toRight && toRight.isDummy)) && (
        <Table game={game} playerPosition={seat} players={players} />
      )}

      {partner && partner.isDummy && (
        <PlayableHand
          onPlayed={onPlayedTop}
          cards={partner && partner.hand.cards}
          top
        />
      )}

      {isPhil(user) && <AssignWinner game={game} players={players} />}

      <PlayableHand
        onPlayed={onPlayed}
        cards={me && me.hand && me.hand.cards}
        bottom
      />

      {isPhil(user) && (
        <Link href="/options">
          options
        </Link>
      )}
    </div>
  );
};

export default App;
