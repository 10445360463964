import React from "react";
import styled, { css } from "styled-components";
import Hand from "./Hand";

const topCss = css`
  top: 50px;
`;

const bottomCss = css`
  bottom: 20px;
`;

const HandWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  ${p => p.top && topCss}
  ${p => p.bottom && bottomCss}
`;

const SideHand = ({ cards, onPlayed, isDummy, top = false, bottom = false }) => {
  return (
    <HandWrapper top={top} bottom={bottom}>
      <Hand onPlayed={onPlayed} cards={cards} isTopHand={!!top} isDummy={isDummy} />
    </HandWrapper>
  );
};

export default SideHand;
