export const SPADE = "♠";
export const HEART = "♥";
export const DIAMOND = "♦";
export const CLUB = "♣";

export const SUIT_SCORES = {
  [SPADE]: 4,
  [HEART]: 3,
  [DIAMOND]: 2,
  [CLUB]: 1
}

export const VALUE_SCORES = {
  "J": 11,
  "Q": 12,
  "K": 13,
  "A": 14
}

export const scoreCard = ({ suit, value }) => -1 * (100 * SUIT_SCORES[suit]) - (VALUE_SCORES[value] || value)
export const equalCard = (card1, card2) =>
  card1 && card2 && (card1.suit === card2.suit && card1.value === card2.value)
