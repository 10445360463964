/*
{
  "game": {
    "play": {
      "dealer": "N",
      "dummy": "E",
      "hands": {
        "E": {
          "cards": [ { "suit": "♥", "value": 4 }, ...],
        },
        "N": {
          "cards": [ { "suit": "♥", "value": 4 }, ...]
        }
      },
      "table": {
        "S": { "suit": "♥", "value": 8 }
      }
    },
    "seats": {
      "N": "+31628343674"
    }
  },
  "players": {
    "+31628343674": {
      "name": "Phil"
    },
  }
}
*/

export const phoneBySeat = (gameData, seat) => gameData.seats[seat];
export const nameByPhone = (gameData, phone) => gameData.players[phone].name;

export const playerBySeat = (gameData, seatName) => {
  const { game, players } = gameData;

  if (!game || !game.seats || !game.seats[seatName]) return { seat: seatName };
  if (!players) return { seat: seatName };

  const phone = game.seats[seatName];
  const name = players[phone] && players[phone].name;
  const play = game.play;
  const isDummy = play && play.dummy && play.dummy === seatName;

  return {
    seat: seatName,
    phone,
    name,
    hand: play && play.hands[seatName],
    isDealer: play && play.dealer === seatName,
    onTable: play && play.table && play.table[seatName],
    isDummy
  };
};

export const playerByPhone = (players, phone) => {
  const seat = ["N", "E", "S", "W"].find(k => players[k].phone === phone);
  return players[seat];
};

export const playersBySeat = gameData => ({
  N: playerBySeat(gameData, "N"),
  E: playerBySeat(gameData, "E"),
  S: playerBySeat(gameData, "S"),
  W: playerBySeat(gameData, "W")
});

export const oppositeSeat = seat => {
  const seats = ["N", "E", "S", "W"];
  const opposite = seats[(seats.indexOf(seat) + 2) % 4];
  return opposite;
};

export const nextSeat = seat => {
  const seats = ["N", "E", "S", "W"];
  const opposite = seats[(seats.indexOf(seat) + 1) % 4];
  return opposite;
}

export const prevSeat = seat => {
  const seats = ["N", "E", "S", "W"];
  const opposite = seats[(seats.indexOf(seat) + 3) % 4];
  return opposite;
}
