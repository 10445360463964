import React, { useState } from "react";
import "./Login.css";

const Login = ({ onSubmit }) => {
  const [name, setName] = useState("");

  const submit = () => {
    if (name.length > 0) {
      onSubmit(name);
    }
  };

  return (
    <div className="Login">
      <label htmlFor="name">What's your name?</label>
      <input
        autoFocus
        id="name"
        value={name}
        onChange={e => setName(e.target.value)}
        placeholder="Bridget Jones"
      />
      <button onClick={submit}>Login</button>
    </div>
  );
};

export default Login;
