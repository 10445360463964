import React from "react";
import { takeSeat } from "./api";
import "./SeatPicker.css";
import Link from "./Link";

const findSeat = (seats, phoneNumber) => {
  if (!seats) return;

  return Object.keys(seats).find(position => seats[position] === phoneNumber);
};

const getPlayerName = (game, players, position) => {
  if (!game || !game.seats || !players) {
    return null;
  }

  const player = players[game.seats[position]];
  return (player && player.name) || null;
};

const isPhil = user => {
  if (!user) throw new Error("no user");
  if (!user.phoneNumber) return null;
  return user.phoneNumber.match(/31628343674$/);
};

const SeatPicker = ({ game, players, user }) => {
  const seats = (game && game.seats) || {};
  const isSeated = findSeat(seats, user.phoneNumber);

  return (
    <div className="SeatPicker__Page">
      <h1>{isSeated ? "Waiting for players..." : "Choose a position"}</h1>

      {isPhil(user) && (
        <Link href="/options">
          options
        </Link>
      )}

      <div className="SeatPicker">
        <button
          className="SeatPicker__Seat--N"
          disabled={!!(isSeated || seats.N)}
          onClick={() => takeSeat(user.phoneNumber, "N")}
        >
          {getPlayerName(game, players, "N") || "North"}
        </button>
        <button
          className="SeatPicker__Seat--E"
          disabled={!!(isSeated || seats.E)}
          onClick={() => takeSeat(user.phoneNumber, "E")}
        >
          {getPlayerName(game, players, "E") || "East"}
        </button>
        <button
          className="SeatPicker__Seat--S"
          disabled={!!(isSeated || seats.S)}
          onClick={() => takeSeat(user.phoneNumber, "S")}
        >
          {getPlayerName(game, players, "S") || "South"}
        </button>
        <button
          className="SeatPicker__Seat--W"
          disabled={!!(isSeated || seats.W)}
          onClick={() => takeSeat(user.phoneNumber, "W")}
        >
          {getPlayerName(game, players, "W") || "West"}
        </button>
      </div>
    </div>
  );
};

export default SeatPicker;
