import { useState, useEffect } from "react";
import { EventEmitter } from "events";

const emitter = new EventEmitter();

const freeze = o => (Object.freeze ? Object.freeze(o) : o);

const getCurrentUrlObj = () => {
  const { search, pathname } = window.location;
  return freeze({
    pathname,
    search: freeze(
      search
        .slice(1)
        .split("&")
        .reduce((obj, entry) => {
          const [key, val] = entry.split("=");
          obj[key] = val;
          return obj;
        }, {})
    )
  });
};

export const useUrl = () => {
  const [url, setUrl] = useState(getCurrentUrlObj());

  useEffect(() => {
    const cb = () => setUrl(getCurrentUrlObj())
    emitter.on("url", cb)
    window.addEventListener("popstate", cb);
    return () => {
      emitter.removeListener("url", cb);
      window.removeEventListener("popstate", cb);
    };
  }, []);

  return url;
};

export const updateUrl = (path, search = {}) => {
  path = path || window.location.pathname;
  search = Object.keys(search)
    .map(k => `${k}=${search[k]}`)
    .join("=");
  window.history.pushState(null, null, `${path}?${search}`);
  emitter.emit("url");
};
