import React from "react";
import "./AssignWinner.css";
import { assignWinner } from "./api";

const getName = (game, players, position) => players[game.seats[position]].name;

const AssignWinner = ({ game, players }) => {
  const played = game && game.play && game.play.table;

  if (!played || !played.N || !played.E || !played.S || !played.W) return null;

  const onAssignWinner = position => {
    if (
      // eslint-disable-next-line
      confirm(
        `Are you sure it was ${getName(game, players, position)} who won?`
      )
    ) {
      const cards = Object.keys(played).map(k => played[k]);
      assignWinner(position, cards);
    }
  };

  return (
    <div className="AssignWinner">
      <h1>Who won?</h1>
      <div className="AssignWinner__Buttons">
        <button onClick={() => onAssignWinner("N")}>
          {getName(game, players, "N")}
        </button>
        <button onClick={() => onAssignWinner("E")}>
          {getName(game, players, "E")}
        </button>
        <button onClick={() => onAssignWinner("S")}>
          {getName(game, players, "S")}
        </button>
        <button onClick={() => onAssignWinner("W")}>
          {getName(game, players, "W")}
        </button>
      </div>
    </div>
  );
};

export default AssignWinner;
