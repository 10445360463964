import React from "react";
import styled, { css } from "styled-components";
import Hand from "./Hand";

const getWidth = () => {
  const width = Math.floor(document.documentElement.clientWidth / 13);
  return width;
}

const getHeight = () => {
  const height = Math.floor(getWidth() * 1.5);
  return height * 4 + 10;
}


const HandWrapper = styled.div`
  width: 50vw;
  height: ${getHeight()}px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${p =>
    p.left
      ? css`
          left: 10px;
        `
      : css`
          right: ${getWidth()}px;
          text-align: right;
        `}
`;

const SideHand = ({ cards, left = false, right = false }) => {
  return (
    <HandWrapper left={left} right={right}>
      <Hand vertical cards={cards} reverse={right} />
    </HandWrapper>
  );
};

export default SideHand;
